<template>
  <div class="slider_area clear-fix" dir="ltr">
    <Agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
      <div
        class="slide"
        v-for="(slide, index) in slideList"
        :key="index"
        :class="`slide--${index}`"
      >
        <img
          v-bind="mainImgProps"
          :src="slide"
          :alt="altText"
          @error="showDefaultImage"
        />
      </div>
    </Agile>
    <Agile
      class="thumbnails"
      ref="thumbnails"
      :options="options2"
      :as-nav-for="asNavFor2"
      v-if="showThumb"
    >
      <div
        class="slide slide--thumbniail"
        v-for="(slide, index) in slideList"
        :key="index"
        :class="`slide--${index}`"
        @click="$refs.main.goTo(index)"
      >
        <img
          v-bind="mainImgProps"
          :src="slide"
          :alt="altText"
          @error="showDefaultImage"
        />
      </div>
    </Agile>
  </div>
</template>

<script>

export default {
  name: 'Carousel',
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    showThumb: {
      type: Boolean,
      default: true,
    },
    numThumb: {
      type: Number,
      default: 3,
    },
    defaultImage: {
      type: String,
      default: '',
    },
    altText: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        navButtons: false,
      },
      options2: {
        autoplay: true,
        dots: false,
        centerMode: true,
        navButtons: false,
        slidesToShow: this.numThumb,
      },
      mainImgProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
      },
    };
  },
  computed: {
    slideList() {
      const tmpList = [
        '/assets/img/app/banner1.jpg',
        '/assets/img/app/banner1.jpg',
        '/assets/img/app/banner1.jpg',
        '/assets/img/app/banner1.jpg',
      ];
      return this.slides.length === 0 ? tmpList : this.slides;
    },
  },
  beforeMount() {
  },
  mounted() {
    setTimeout(() => {
      this.asNavFor1.push(this.$refs.thumbnails);
      this.asNavFor2.push(this.$refs.main);
    }, 2000);
  },
  methods: {
    showDefaultImage(ev) {
      ev.target.src = this.defaultImage;
    },
  },
};
</script>

<style scoped>
.main {
  margin-bottom: 10px;
}

.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}
.thumbnails .agile__nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.thumbnails .agile__nav-button--prev {
  left: -45px;
}
.thumbnails .agile__nav-button--next {
  right: -45px;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button, .agile__dot:hover button {
  background-color: #888;
}

.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 450px;
  justify-content: center;
}
.slide--thumbniail {
  cursor: pointer;
  height: 85px;
  padding: 0 5px;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  border-radius: 10px;
}
</style>
